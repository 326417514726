import * as React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { Grid, Card, CardActions, CardContent } from "@mui/material";
import { Button } from "gatsby-material-ui-components";
import Typography from "@mui/material/Typography";

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <title>
          Encryption, Hashing, and Encoding Tools - AES, MD5, SHA256, Base64 |
          TxtWizard
        </title>
        <meta
          name="description"
          content="provides various encryption tools such as AES/GCM and AES/CBC, hash generating tools like MD5, SHA1, SHA256, SHA3, and encoding options including Base64 and HEX. Try our easy-to-use online tools today!"
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9932778305312246"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Encryption
              </Typography>
              <Typography variant="body2">
                AES/GCM and AES/CBC encryption.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" to="/crypto" color="primary">
                Go
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Hash
              </Typography>
              <Typography variant="body2">
                hash for text using MD5, SHA, and SHA3.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" to="/hash" color="primary">
                Go
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Compress
              </Typography>
              <Typography variant="body2">
                GZIP compression. Base64 encoded output.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" to="/compression" color="primary">
                Go
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Encoding
              </Typography>
              <Typography variant="body2">
                URL Encoding, Base64 Encoding, and HEX Encoding.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" to="/encoding" color="primary">
                Go
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <p>
        Txtwizard.net is a specialized website designed to provide developers
        with powerful text manipulation tools. This platform is particularly
        valuable for development work, offering functionalities that run
        entirely on the client-side, ensuring efficient and secure processing.
      </p>
      <ul>
        <li>
          <strong>Encoding:</strong> Includes URL Encoding, Base64 Encoding, and
          HEX Encoding.
        </li>
        <li>
          <strong>Encrypting with AES:</strong> Advanced Encryption Standard for
          secure data handling.
        </li>
        <li>
          <strong>Hash Functions:</strong> Generate hashes for data integrity
          and security.
        </li>
        <li>
          <strong>Compression Tools:</strong> Efficiently compress data for
          optimized storage and transfer.
        </li>
        <li>
          <strong>String Utilities:</strong> A variety of string manipulation
          options for various development needs.
        </li>
      </ul>
      <p>
        All these features are implemented to run on the frontend, providing
        developers with a quick, accessible, and reliable suite of tools for
        their coding and data processing needs.
      </p>
    </Layout>
  );
}
